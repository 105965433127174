div.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
    height: 100vh;
}

.main-content {
    margin-left: 255px;
}

@media screen and (max-width: 600px) {
    .main-content {
        margin-left: 0;
    }
}

.MuiListItem-root.Mui-selected {
    background-color: #ffd757 !important;
}

/* Reduce nav bar width to the size of drawerMarginLeft */
.MuiAppBar-positionFixed {
    left: 0 !important;
}

.react-flow__edge .react-flow__edge-ConversationItemOptionEdge {
    stroke-width: 3px;
    color: #111;
}

path.react-flow__edge-path {
    stroke-width: 5px;
}

textPath {
    font-size: 24px;
}
